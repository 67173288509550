import React from "react";


export function Logo({logourl}) {
  return (
    <img 
      src={logourl}
      height='50px'
      alt=""
    />
  );
}
